<div id="layout-wrapper">
  <app-toasts></app-toasts>
  <app-topbar
    [ngStyle]="{display: hideLayout ? 'none' : 'block'}"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
  >
  </app-topbar>

  <app-sidebar
    [isCondensed]="isCondensed"
    [ngStyle]="{display: hideLayout ? 'none' : 'block'}"
  ></app-sidebar>

  <div class="main-content">
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>
