import {Component, OnInit} from '@angular/core';
import {Client} from 'src/app/shared/data/model/client.model';
import {ClientRestService} from 'src/app/shared/data/service/client-rest.service';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectSystemVersion} from '../../core/system/state/system.selectors';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();

  client$: Observable<Client>;
  version$: Observable<string> = this.store.select(selectSystemVersion);

  constructor(private clientRestService: ClientRestService, private store: Store) {}

  ngOnInit() {
    this.client$ = this.clientRestService.findActiveClient();
  }
}
