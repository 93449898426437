<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <a href="https://www.e-twi.com/" target="”_blank”">
          <img
            src="assets/images/logo-dark.png"
            alt=""
            height="25"
          />
        </a>
      </div>
      <div class="col-sm-6">
        <div
          class="text-sm-right d-none d-sm-block"
          *ngIf="client$ | async as client"
        >
          <a
            class="btn btn-secondary btn-sm btn-rounded"
            *ngIf="client.showSupportUrl"
            href="{{ client.supportUrl }}"
            target="”_blank”"
          >
            Support
          </a>
          <span class="ml-3"
            >{{ year }} © Design & Develop by <b>Do Lean IT OÜ</b></span
          >
          <span class="ml-1"> v{{ version$ | async }}.66</span>
        </div>
      </div>
    </div>
  </div>
</footer>
