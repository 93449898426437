import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccountType, User} from '../model/user.model';
import {AccountTypeRestService} from './account-type-rest.service';
import {isUpdate, url, zeroIfNull} from '../server/rest.util';
import {
  ADD_USER,
  GET_MAIL_LIST,
  GET_USER,
  GET_USERS,
  REMOVE_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
} from '../server/rest-endpoint.constant';
import {Response} from '../server/response.model';

export interface GetUser {
  id: number;
  firstName: string;
  lastName: string;
  accountTypeId: number;
  position: string;
  phoneNumber?: string;
  email: string;
  isRemoved: boolean;
  image: string;
  fileName: string;
  verificationDepartments: BackendVerificationDepartment[];
  departmentId: number;
  departmentName: string;
  plantId: number;

  departments: BackendDepartment[];
  allowAllDepartments: boolean;
}

export interface BackendDepartment {
  id: number;
  name: string;
}

export interface BackendVerificationDepartment {
  id: number;
  name: string;
}

export interface BackendId {
  id: number;
}

export interface UpdateUser {
  id?: number;
  firstName: string;
  lastName: string;
  accountTypeId: number;
  verificationDepartmentIds: BackendId[];
  departmentId: number | null;
  position: string;
  phoneNumber?: string;
  email: string;
  image: string;
  password?: string;
  plantId: number;

  departmentIds: BackendId[];
  allowAllDepartments: boolean;
  isPasswordRequired?: boolean;
}

@Injectable({providedIn: 'root'})
export class UserRestService {
  constructor(private http: HttpClient, private accountTypeRestService: AccountTypeRestService) {}

  static mapUserToUpdateUser(user: User): UpdateUser {
    return {
      id: zeroIfNull(user.id),
      firstName: user.firstname,
      lastName: user.surname,
      position: user.position,
      phoneNumber: user.phone,
      plantId: user.plantId,
      email: user.email,
      image: user.image,
      accountTypeId: user.accountType.id,
      password: user.password,
      verificationDepartmentIds:
        user.verificationDepartments?.map(dep => {
          return {
            id: dep.id,
          };
        }) || [],
      departmentId: user.department?.id !== undefined ? user.department.id : null,
      departmentIds:
        user.allowedDepartmentIds?.map(id => {
          return {id};
        }) || [],
      allowAllDepartments: user.allowAllDepartments,
      isPasswordRequired: user.isPasswordRequired,
    };
  }

  static mapGetUserToUser(user: GetUser, accountTypes: AccountType[]): User {
    return {
      id: user.id,
      firstname: user.firstName,
      surname: user.lastName,
      position: user.position,
      phone: user.phoneNumber,
      email: user.email,
      image: user.image,
      isRemoved: user.isRemoved,
      plantId: user.plantId,
      accountType: accountTypes.filter(accountType => accountType.id === user.accountTypeId)[0],
      verificationDepartments: user.verificationDepartments?.map(dep => {
        return {
          id: dep.id,
          name: dep.name,
        };
      }),
      department:
        user.departmentId !== null && user.departmentId !== undefined
          ? {
              id: user.departmentId,
              name: user.departmentName,
              shortcut: '',
              areas: [],
              parentId: user.plantId,
            }
          : null,
      allowedDepartmentIds: user.departments?.map(dep => dep.id),
      allowAllDepartments: user.allowAllDepartments,
    };
  }

  public updatePassword(userId: number, currentPassword: string, newPassword: string): Observable<Response<any>> {
    return this.http.put<Response<any>>(url(UPDATE_USER_PASSWORD), {
      id: userId,
      currentPassword,
      newPassword,
    });
  }

  public save(user: User): Observable<any> {
    if (isUpdate(user)) {
      return this.http.put(url(UPDATE_USER), UserRestService.mapUserToUpdateUser(user));
    } else {
      return this.http.post(url(ADD_USER), UserRestService.mapUserToUpdateUser(user));
    }
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(url(REMOVE_USER), {
      params: new HttpParams().set('userId', id + ''),
    });
  }

  public findMailList(): Observable<string[]> {
    return this.http.get<Response<string[]>>(url(GET_MAIL_LIST)).pipe(map(data => data.result));
  }

  public findAll(): Observable<User[]> {
    return forkJoin({
      accountTypes: this.accountTypeRestService.findAllAccountTypes(),
      users: this.http.get<GetUser[]>(url(GET_USERS)),
    }).pipe(
      map(data => {
        return data.users.map(user => UserRestService.mapGetUserToUser(user, data.accountTypes));
      }),
    );
  }

  public find(id: number): Observable<User> {
    return forkJoin({
      accountTypes: this.accountTypeRestService.findAllAccountTypes(),
      user: this.http.get<GetUser>(url(GET_USER), {
        params: new HttpParams().set('id', id + ''),
      }),
    }).pipe(
      map(data => {
        return UserRestService.mapGetUserToUser(data.user, data.accountTypes);
      }),
    );
  }
}
