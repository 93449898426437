import {Dictionary} from '../server/response.model';

export interface Status {
  id: number;
  name: StatusName;
  label: string;
}

export enum StatusName {
  TODO,
  DURING_VERIFICATION,
  RELEASED,
  TO_CORRECT,
  TO_ACCEPT,
  ARCHIVED,
}

export interface StatusDictionary extends Dictionary<StatusName> {}

export const STATUSES_DICTIONARY: StatusDictionary[] = [
  {
    name: StatusName.TODO,
    backendName: 'ToDo',
    label: 'PAGES.COMMON.INSTRUCTION.STATUS.TODO',
  },
  {
    name: StatusName.DURING_VERIFICATION,
    backendName: 'DuringVerification',
    label: 'PAGES.COMMON.INSTRUCTION.STATUS.DURING_VERIFICATION',
  },
  {
    name: StatusName.RELEASED,
    backendName: 'Released',
    label: 'PAGES.COMMON.INSTRUCTION.STATUS.RELEASED',
  },
  {
    name: StatusName.TO_CORRECT,
    backendName: 'ToCorrect',
    label: 'PAGES.COMMON.INSTRUCTION.STATUS.TO_CORRECT',
  },
  {
    name: StatusName.TO_ACCEPT,
    backendName: 'ToAccept',
    label: 'PAGES.COMMON.INSTRUCTION.STATUS.TO_ACCEPT',
  },
  {
    name: StatusName.ARCHIVED,
    backendName: 'Archived',
    label: 'PAGES.COMMON.INSTRUCTION.STATUS.ARCHIVED',
  },
];
