import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {Response} from '../server/response.model';
import {NODE_TYPES_DICTIONARY, NodeType} from '../model/node-type.model';
import {url} from '../server/rest.util';
import {GET_NODE_TYPES} from '../server/rest-endpoint.constant';

export interface BackendNodeType {
  id: number;
  name: string;
}

@Injectable({providedIn: 'root'})
export class NodeTypeRestService {
  constructor(private http: HttpClient) {}

  private nodeTypes: Observable<NodeType[]>;

  public findAll(): Observable<NodeType[]> {
    if (!this.nodeTypes) {
      this.nodeTypes = this.http.get<Response<BackendNodeType[]>>(url(GET_NODE_TYPES)).pipe(
        map(response => this.mapNodeTypes(response.result)),
        publishReplay(1),
        refCount(),
      );
    }

    return this.nodeTypes;
  }

  private mapNodeTypes(backendNodeTypes: BackendNodeType[]): NodeType[] {
    return backendNodeTypes
      ? backendNodeTypes
          .map(backendNodeType => {
            const foundNodeType = NODE_TYPES_DICTIONARY.filter(dic => dic.backendName === backendNodeType.name);
            if (foundNodeType?.length > 0) {
              return {
                id: backendNodeType.id,
                name: foundNodeType[0].name,
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }
}
