import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {Language} from '../model/language.model';
import {Response} from '../server/response.model';
import {url} from '../server/rest.util';
import {GET_LANGUAGES} from '../server/rest-endpoint.constant';

export interface GetLanguage {
  id: number;
  code: string;
  name: string;
  englishName: string;
  alphaCode2: string;
  alphaCode3: string;
}

@Injectable({providedIn: 'root'})
export class LanguageRestService {
  constructor(private http: HttpClient) {}

  private language: Observable<Language>;

  public findAll(): Observable<Language> {
    if (!this.language) {
      this.language = this.http.get<Response<GetLanguage[]>>(url(GET_LANGUAGES)).pipe(
        map(
          response =>
            response.result
              .filter(language => language.alphaCode2 === 'PL')
              .map(language => {
                return {
                  id: language.id,
                  code: language.alphaCode2.toLowerCase(),
                };
              })[0],
        ),
        publishReplay(1),
        refCount(),
      );
    }

    return this.language;
  }
}
