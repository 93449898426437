import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {AccountTypeName, RoleName} from '../../shared/data/model/user.model';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {LoggedUser} from '../../shared/data/model/logged-user.models';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  private static isEmptyArray(roles: RoleName[]): boolean {
    return !roles || roles.length === 0;
  }

  private static hasAnyRole(user: LoggedUser, roles: RoleName[]): boolean {
    return AuthGuard.isEmptyArray(roles) || user?.accountType?.roles?.map(role => role.name).some(role => roles.includes(role));
  }

  hasAnyRole(roles: RoleName[]): boolean {
    const activeUser: LoggedUser = this.authenticationService.currentUserValue;
    return activeUser && AuthGuard.hasAnyRole(activeUser, roles);
  }

  private isSelfEdit(user: LoggedUser, route: ActivatedRouteSnapshot) {
    return !!route.data?.checkIfSelfEdit && Number(route.paramMap.get(route.data?.paramName)) === user.id;
  }

  private isViewer(user: LoggedUser): boolean {
    return user?.accountType?.name === AccountTypeName.VIEWER;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const activeUser: LoggedUser = this.authenticationService.currentUserValue;
    const redirection = this.isViewer(activeUser) ? '/instructions/released' : '/';
    if (activeUser) {
      if (AuthGuard.hasAnyRole(activeUser, route.data?.roles) || this.isSelfEdit(activeUser, route)) {
        return true;
      } else {
        this.router.navigate([redirection]);
        return false;
      }
    }

    this.router.navigate(['/account/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;

    const activeUser: LoggedUser = this.authenticationService.currentUserValue;
    return activeUser ? AuthGuard.hasAnyRole(activeUser, route.data?.roles) : false;
  }
}
