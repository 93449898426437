import {Dictionary} from '../server/response.model';

export interface VerificationStatus {
  id: number;
  label: string;
  name: VerificationStatusName;
}

export interface VerificationStatusId {
  id: number;
}

export enum VerificationStatusName {
  ACCEPTED,
  REJECTED,
  TODO,
  IN_PROGRESS,
}

export interface VerificationStatusDictionary extends Dictionary<VerificationStatusName> {}

export const VERIFICATION_STATUSES_DICTIONARY: VerificationStatusDictionary[] = [
  {
    backendName: 'Accepted',
    name: VerificationStatusName.ACCEPTED,
    label: 'PAGES.COMMON.INSTRUCTION.VERIFICATION_STATUS.ACCEPTED',
  },
  {
    backendName: 'Rejected',
    name: VerificationStatusName.REJECTED,
    label: 'PAGES.COMMON.INSTRUCTION.VERIFICATION_STATUS.REJECTED',
  },
  {
    backendName: 'ToDo',
    name: VerificationStatusName.TODO,
    label: 'PAGES.COMMON.INSTRUCTION.VERIFICATION_STATUS.TODO',
  },
  {
    backendName: 'InProgress',
    name: VerificationStatusName.IN_PROGRESS,
    label: 'PAGES.COMMON.INSTRUCTION.VERIFICATION_STATUS.IN_PROGRESS',
  },
];
