import {Component, Input} from '@angular/core';
import {first} from 'lodash';
import {InstructionVersion} from '../../data/model/instruction-list.model';

@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
})
export class QrGeneratorComponent {
  @Input() public url: string;
  @Input() public versionId: number;

  public qrCanvas: HTMLCanvasElement;
  public qrCanvasContext: CanvasRenderingContext2D;
  public qrSize = 300;
  private canvasHeight = 550;
  private canvasWidth = 800;
  private canvasMargin = 20;
  private logoSize = 50;

  constructor() {}

  public generateBaseForQR = (instruction: InstructionVersion): void => {
    this.qrCanvas = first(document.getElementsByClassName('qrCanvas instruction-' + instruction.id)) as HTMLCanvasElement;
    this.qrCanvas.height = this.canvasHeight;
    this.qrCanvas.width = this.canvasWidth;
    this.qrCanvasContext = this.qrCanvas.getContext('2d');
    this.qrCanvasContext.fillStyle = '#fff';
    this.qrCanvasContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    let logo = new Image();
    logo.src = '../../../../../../assets/images/logo-e.png';
    logo.onload = () => {
      this.qrCanvasContext.drawImage(logo, this.canvasMargin, this.canvasMargin, this.logoSize, this.logoSize);
      this.createQR(instruction);
    };
  };

  private createQR = (instruction: InstructionVersion): void => {
    const qrImage = first(document.getElementsByClassName('instruction-' + instruction.id)).firstChild as HTMLImageElement;
    this.qrCanvasContext.font = '30px poppins';
    this.qrCanvasContext.fillStyle = '#212529';
    this.qrCanvasContext.fillText(instruction.name, this.canvasMargin, 200, this.canvasWidth - 2 * this.canvasMargin);
    this.qrCanvasContext.font = '20px poppins';
    this.qrCanvasContext.fillText(instruction.number, this.canvasMargin, 240, this.canvasWidth - 2 * this.canvasMargin);
    this.qrCanvasContext.drawImage(
      qrImage,
      (this.canvasWidth - this.qrSize) / 2,
      this.canvasHeight - this.qrSize,
      this.qrSize,
      this.qrSize,
    );
    this.downloadQR(this.qrCanvas.toDataURL('image/png'), instruction.id);
    this.qrCanvasContext.fillStyle = '#fff';
    this.qrCanvasContext.fillRect(0, this.logoSize + this.canvasMargin, this.canvasWidth, this.canvasMargin);
  };

  private downloadQR = (base64: string, instructionId: number): void => {
    fetch(base64)
      .then(response => response.blob())
      .then((blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        const generatedImg = document.createElement('a');
        generatedImg.href = url;
        generatedImg.download = 'instruction-' + instructionId + '-qr';
        generatedImg.click();
      });
  };
}
