import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {Response} from '../server/response.model';
import {Status, STATUSES_DICTIONARY} from '../model/instruction-status.model';
import {url} from '../server/rest.util';
import {GET_INSTRUCTION, GET_INSTRUCTION_STATUSES} from '../server/rest-endpoint.constant';
import {InstructionBackendStatus} from '../model/instruction-backend-status.model';
@Injectable({providedIn: 'root'})
export class InstructionStatusRestService {
  constructor(private http: HttpClient) {}

  private statuses: Observable<Status[]>;

  private mapStatuses(backendStatuses: InstructionBackendStatus[]): Status[] {
    return backendStatuses
      ? backendStatuses
          .map(backendStatus => {
            const foundStatuses = STATUSES_DICTIONARY.filter(dic => dic.backendName === backendStatus.name);
            if (foundStatuses?.length > 0) {
              return {
                id: backendStatus.id,
                name: foundStatuses[0].name,
                label: foundStatuses[0].label,
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }

  findAll(): Observable<Status[]> {
    if (!this.statuses) {
      this.statuses = this.http.get<Response<InstructionBackendStatus[]>>(url(GET_INSTRUCTION_STATUSES)).pipe(
        map(response => this.mapStatuses(response.result)),
        publishReplay(1),
        refCount(),
      );
    }

    return this.statuses;
  }
}
