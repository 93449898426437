import {Dictionary} from '../server/response.model';

export interface TimeUnit {
  id: number;
  value: string;
}

export interface TimeUnitDictionary extends Dictionary<string> {}

export const TIME_UNITS_DICTIONARY: TimeUnitDictionary[] = [
  {
    name: '-',
    backendName: 'None',
    label: '-',
  },
  {
    name: 's',
    backendName: 'Seconds',
    label: 's',
  },
  {
    name: 'min',
    backendName: 'Minutes',
    label: 'min',
  },
];
