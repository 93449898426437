import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {LoaderService} from '../../../core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  public loading: boolean;

  constructor(private cdr: ChangeDetectorRef, private destroyRef: DestroyRef, private loaderService: LoaderService) {}

  public ngOnInit(): void {
    this.loaderService.loaders.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      this.loading = v.length > 0;
      this.cdr.detectChanges();
    });
  }
}
