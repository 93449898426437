<qrcode
  class="d-inline-block"
  [scale]="1"
  [width]="qrSize"
  [colorDark]="'#000000ff'"
  [colorLight]="'#fff'"
  [qrdata]="url + '/#/instructions/' + versionId + '/preview/qrcode'"
  [errorCorrectionLevel]="'H'"
  [elementType]="'img'"
  [cssClass]="'qrcode d-none instruction-' + versionId"
>
</qrcode>
<canvas class="qrCanvas d-none" [ngClass]="'instruction-' + versionId"></canvas>
