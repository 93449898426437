import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {Response} from '../server/response.model';
import {ACCOUNT_TYPES_DICTIONARY, AccountType, Role, ROLES_DICTIONARY} from '../model/user.model';
import {url} from '../server/rest.util';
import {ADD_ACCOUNT_TYPE, GET_ACCOUNT_TYPES, GET_ROLES, REMOVE_ACCOUNT_TYPE, UPDATE_ACCOUNT_TYPE} from '../server/rest-endpoint.constant';

export interface BackendRole {
  id: number;
  name: string;
}

export interface BackendAccountType {
  id: number;
  name: string;
  predefined: boolean;
}

export interface GetBackendAccountType extends BackendAccountType {
  accountTypeRoles: BackendRole[];
}

export interface SaveBackendAccountType extends BackendAccountType {
  roles: BackendRole[];
}

export interface AddAccountTypeRest {
  id?: number;
  name: string;
  accountTypeRoles: BackendRole[];
}

@Injectable({providedIn: 'root'})
export class AccountTypeRestService {
  constructor(private http: HttpClient) {}

  private accountTypes: Observable<AccountType[]>;
  private roles: Observable<Role[]>;

  clearAccountTypesCache(): void {
    this.accountTypes = null;
  }

  public updateAccountType = (accountTypeRest: AddAccountTypeRest): Observable<any> => {
    return this.http.put<Response<boolean>>(url(UPDATE_ACCOUNT_TYPE), accountTypeRest).pipe(
      switchMap(data => {
        this.clearAccountTypesCache();
        return this.findAllAccountTypes();
      }),
    );
  };

  public addAccountType = (accountTypeRest: AddAccountTypeRest): Observable<any> => {
    return this.http.put<Response<boolean>>(url(ADD_ACCOUNT_TYPE), accountTypeRest).pipe(
      switchMap(data => {
        this.clearAccountTypesCache();
        return this.findAllAccountTypes();
      }),
    );
  };

  public deleteAccountType = (accountTypeId: number): Observable<any> => {
    return this.http
      .delete<Response<boolean>>(url(REMOVE_ACCOUNT_TYPE), {
        params: new HttpParams().set('accountTypeId', accountTypeId + ''),
      })
      .pipe(
        switchMap(data => {
          this.clearAccountTypesCache();
          return this.findAllAccountTypes();
        }),
      );
  };

  private mapBackendRolesToRoles(backendRoles: BackendRole[]): Role[] {
    return backendRoles
      ? backendRoles
          .map(backendRole => {
            const foundRoles = ROLES_DICTIONARY.filter(dic => dic.backendName === backendRole.name);
            if (foundRoles?.length > 0) {
              return {
                id: backendRole.id,
                name: foundRoles[0].name,
                label: foundRoles[0].label,
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }

  private mapRolesToBackendRoles(roles: Role[]): BackendRole[] {
    return roles
      ? roles
          .map(role => {
            const foundRoles = ROLES_DICTIONARY.filter(dic => dic.name === role.name);
            if (foundRoles?.length > 0) {
              return {
                id: role.id,
                name: foundRoles[0].backendName,
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }

  private mapAccountTypesToBackendAccountTypes(accountTypes: AccountType[]): SaveBackendAccountType[] {
    return accountTypes
      ? accountTypes.map(accountType => {
          return {
            id: accountType.id,
            name: accountType.label,
            predefined: false,
            roles: this.mapRolesToBackendRoles(accountType.roles),
          };
        })
      : [];
  }

  private mapBackendAccountTypesToAccountTypes(backendAccountTypes: GetBackendAccountType[]): AccountType[] {
    return backendAccountTypes
      ? backendAccountTypes
          .map(backendAccountType => {
            const foundAccountTypes = ACCOUNT_TYPES_DICTIONARY.filter(dic => dic.backendName === backendAccountType.name);

            if (foundAccountTypes?.length > 0 && backendAccountType.predefined) {
              return {
                id: backendAccountType.id,
                name: foundAccountTypes[0].name,
                label: foundAccountTypes[0].label,
                predefined: true,
                roles: this.mapBackendRolesToRoles(backendAccountType.accountTypeRoles),
              };
            } else if (!backendAccountType.predefined) {
              return {
                id: backendAccountType.id,
                name: backendAccountType.name,
                label: backendAccountType.name,
                predefined: false,
                roles: this.mapBackendRolesToRoles(backendAccountType.accountTypeRoles),
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }

  findAllAccountTypes(): Observable<AccountType[]> {
    if (!this.accountTypes) {
      this.accountTypes = this.http.get<Response<GetBackendAccountType[]>>(url(GET_ACCOUNT_TYPES)).pipe(
        map((data: Response<GetBackendAccountType[]>) => this.mapBackendAccountTypesToAccountTypes(data.result)),
        publishReplay(1),
        refCount(),
      );
    }

    return this.accountTypes;
  }

  findAllRoles(): Observable<Role[]> {
    if (!this.roles) {
      this.roles = this.http.get<Response<BackendRole[]>>(url(GET_ROLES)).pipe(
        map((data: Response<BackendRole[]>) =>
          data.result
            .map(backendRole => {
              const foundRole = ROLES_DICTIONARY.filter(dic => dic.backendName === backendRole.name);

              if (foundRole?.length > 0) {
                return {
                  id: backendRole.id,
                  name: foundRole[0].name,
                  label: foundRole[0].label,
                };
              } else {
                return null;
              }
            })
            .filter(val => val),
        ),
        publishReplay(1),
        refCount(),
      );
    }

    return this.roles;
  }
}
