import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ActionType} from '../../data/model/instruction.model';
import {SaveType} from '../../data/model/save-type.model';

@Component({
  selector: 'app-save-modal',
  templateUrl: './save-modal.component.html',
})
export class SaveModalComponent {
  public title: string;
  public text: string;
  public actionType: ActionType;
  public saveType = SaveType;

  constructor(public activeModal: NgbActiveModal) {}
}
