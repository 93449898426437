import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Company} from '../model/company.model';
import {Response} from '../server/response.model';
import {url} from '../server/rest.util';
import {map, publishReplay, refCount} from 'rxjs/internal/operators';
import {GET_SYSTEM_PARAMETER_TYPES} from '../server/rest-endpoint.constant';
import {SystemParameterType} from '../model/system-parameter-type.model';

@Injectable({providedIn: 'root'})
export class SystemParameterTypeRestService {
  constructor(private http: HttpClient) {}

  private systemParameterTypes: Observable<SystemParameterType[]>;

  public findAll(): Observable<Company[]> {
    if (!this.systemParameterTypes) {
      this.systemParameterTypes = this.http.get<Response<SystemParameterType[]>>(url(GET_SYSTEM_PARAMETER_TYPES)).pipe(
        map(response => response.result),
        publishReplay(1),
        refCount(),
      );
    }

    return this.systemParameterTypes;
  }
}
