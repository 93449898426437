import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Response} from '../server/response.model';
import {url} from '../server/rest.util';
import {map} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {GET_INSTRUCTION_VERIFICATION_STATUSES} from '../server/rest-endpoint.constant';
import {VERIFICATION_STATUSES_DICTIONARY, VerificationStatus} from '../model/verification-status.model';

export interface BackendVerificationStatus {
  id: number;
  name: string;
}

@Injectable({providedIn: 'root'})
export class InstructionVerificationStatusRestService {
  constructor(private http: HttpClient) {}

  private verificationStatuses: Observable<VerificationStatus[]>;

  public findAll(): Observable<VerificationStatus[]> {
    if (!this.verificationStatuses) {
      this.verificationStatuses = this.http.get<Response<BackendVerificationStatus[]>>(url(GET_INSTRUCTION_VERIFICATION_STATUSES)).pipe(
        map(response => this.mapBackendStatusesToStatuses(response.result)),
        publishReplay(1),
        refCount(),
      );
    }
    return this.verificationStatuses;
  }

  private mapBackendStatusesToStatuses(backendVerificationStatus: BackendVerificationStatus[]): VerificationStatus[] {
    return backendVerificationStatus
      ? backendVerificationStatus
          .map(backendStatus => {
            const foundStatuses = VERIFICATION_STATUSES_DICTIONARY.filter(dic => dic.backendName === backendStatus.name);
            if (foundStatuses?.length > 0) {
              return {
                id: backendStatus.id,
                name: foundStatuses[0].name,
                label: foundStatuses[0].label,
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }
}
