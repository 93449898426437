import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QrGeneratorComponent} from './qr-generator.component';
import {QRCodeModule} from 'angularx-qrcode';

@NgModule({
  declarations: [QrGeneratorComponent],
  imports: [CommonModule, QRCodeModule],
  exports: [QrGeneratorComponent],
})
export class QrGeneratorModule {}
