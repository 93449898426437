import {MenuItem} from './menu.model';
import {RoleName} from '../../shared/data/model/user.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENU_ITEMS.DASHBOARD.TEXT',
    icon: 'bxs-dashboard',
    link: '/dashboard',
    roles: [
      RoleName.INITIALIZE_INSTRUCTION,
      RoleName.FILL_INSTRUCTION,
      RoleName.CREATE_NEW_INSTRUCTION_VERSION,
      RoleName.CLONE_INSTRUCTION,
      RoleName.SEND_INSTRUCTION_TO_TRAINER,
      RoleName.SEND_INSTRUCTION_TO_VERIFICATION,
      RoleName.DELETE_UNRELEASED_INSTRUCTION,
      RoleName.DELETE_RELEASED_INSTRUCTION,
      RoleName.SEND_INSTRUCTION_TO_CORRECT,

      RoleName.ACCEPT_INSTRUCTION_VERIFICATION,
      RoleName.REJECT_INSTRUCTION_VERIFICATION,

      RoleName.ADD_COMMENT_TO_INSTRUCTION,
      RoleName.ACCEPT_INSTRUCTION_COMMENT,
      RoleName.DELETE_INSTRUCTION_COMMENT,

      RoleName.ADD_PREDEFINED_STEP,
      RoleName.DELETE_PREDEFINED_STEP,
    ],
  },
  {
    id: 2,
    label: 'MENU_ITEMS.DOCUMENTATION.TEXT',
    isTitle: true,
  },
  {
    id: 3,
    label: 'MENU_ITEMS.INSTRUCTIONS.TEXT',
    icon: 'bx-receipt',
    roles: [
      RoleName.INITIALIZE_INSTRUCTION,
      RoleName.FILL_INSTRUCTION,
      RoleName.CREATE_NEW_INSTRUCTION_VERSION,
      RoleName.CLONE_INSTRUCTION,
      RoleName.SEND_INSTRUCTION_TO_TRAINER,
      RoleName.SEND_INSTRUCTION_TO_VERIFICATION,
      RoleName.DELETE_UNRELEASED_INSTRUCTION,
      RoleName.DELETE_RELEASED_INSTRUCTION,
      RoleName.SEND_INSTRUCTION_TO_CORRECT,

      RoleName.ACCEPT_INSTRUCTION_VERIFICATION,
      RoleName.REJECT_INSTRUCTION_VERIFICATION,

      RoleName.ADD_COMMENT_TO_INSTRUCTION,
      RoleName.ACCEPT_INSTRUCTION_COMMENT,
      RoleName.DELETE_INSTRUCTION_COMMENT,

      RoleName.ADD_PREDEFINED_STEP,
      RoleName.DELETE_PREDEFINED_STEP,
    ],
    link: '/instructions/all',
  },
  {
    id: 15,
    label: 'MENU_ITEMS.INSTRUCTIONS.TEXT',
    icon: 'bx-receipt',
    roles: [RoleName.VIEW_RELEASED_INSTRUCTION],
    link: '/instructions/released',
  },
  {
    id: 7,
    label: 'MENU_ITEMS.PREDEFINED_STEPS.TEXT',
    icon: 'bx-data',
    link: '/instructions/steps',
    roles: [RoleName.ADD_PREDEFINED_STEP, RoleName.DELETE_PREDEFINED_STEP],
  },
  {
    id: 8,
    label: 'MENU_ITEMS.CONFIGURATION.TEXT',
    isTitle: true,
    roles: [RoleName.MANAGE_COMPANY_PROFILE, RoleName.MANAGE_USERS, RoleName.MANAGE_MAIL_TEMPLATE],
  },
  {
    id: 9,
    label: 'MENU_ITEMS.COMPANY_PROFILE.TEXT',
    icon: 'bx-home-alt',
    link: '/company/profile',
    roles: [RoleName.MANAGE_COMPANY_PROFILE],
  },
  {
    id: 10,
    label: 'MENU_ITEMS.DEPARTMENTS_CONFIG.TEXT',
    icon: 'bx-sitemap',
    link: '/departments/config',
    roles: [RoleName.MANAGE_COMPANY_CONFIG],
  },
  {
    id: 11,
    label: 'MENU_ITEMS.INSTRUCTION_CONFIG.TEXT',
    icon: 'bx-spreadsheet',
    link: '/instructions/config',
    roles: [RoleName.MANAGE_INSTRUCTION_CONFIG],
  },
  {
    id: 12,
    label: 'MENU_ITEMS.VERIFICATION_CONFIG.TEXT',
    icon: 'bx-list-check',
    link: '/verification/config',
    roles: [RoleName.MANAGE_VERIFICATION_CONFIG],
  },
  {
    id: 13,
    label: 'MENU_ITEMS.PERMISSIONS_CONFIG.TEXT',
    icon: 'bx-lock-open-alt',
    link: '/permissions/config',
    roles: [RoleName.MANAGE_USERS],
  },
  {
    id: 14,
    label: 'MENU_ITEMS.USERS.TEXT',
    icon: 'bx-group',
    link: '/users',
    roles: [RoleName.MANAGE_USERS],
  },
  {
    id: 15,
    label: 'MENU_ITEMS.EMAIL_TEMPLATE.TEXT',
    icon: 'bx-mail-send',
    link: '/mail-template',
    roles: [RoleName.MANAGE_MAIL_TEMPLATE],
  },

  {
    id: 16,
    label: 'MENU_ITEMS.PICTOGRAMS.TEXT',
    icon: 'bx-images',
    link: '/pictograms',
    roles: [RoleName.MANAGE_MAIL_TEMPLATE],
  },
  {
    id: 17,
    label: 'MENU_ITEMS.TEMPLATES.TEXT',
    icon: 'bx-spreadsheet',
    link: '/templates',
    roles: [RoleName.MANAGE_INSTRUCTION_CONFIG],
  },

  /*,
  {
    id: 15,
    label: 'MENU_ITEMS.CLOUD.TEXT',
    isTitle: true
  },
  {
    id: 16,
    label: 'MENU_ITEMS.CLIENTS.TEXT',
    icon: 'bx-buildings',
    link: '/clients'
  },*/
];
