import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';


import {NgbAlertModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';

import {UIModule} from '../shared/ui/ui.module';
import {LayoutComponent} from './layout.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {TopbarComponent} from './topbar/topbar.component';
import {FooterComponent} from './footer/footer.component';
import {VerticalComponent} from './vertical/vertical.component';
import {LanguageService} from '../core/services/language.service';
import {TranslateModule} from '@ngx-translate/core';
import {ToastsComponent} from './toasts/toasts.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [LayoutComponent, SidebarComponent, TopbarComponent, FooterComponent, VerticalComponent, ToastsComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        NgbDropdownModule,
        ClickOutsideModule,
        UIModule,
        NgbAlertModule,
        FormsModule,
    ],
  providers: [LanguageService],
})
export class LayoutsModule {}
