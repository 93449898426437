<div role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title mt-0">{{ title | translate }}</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ text | translate }}
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="actionType === 'SAVE'; else confirmButtons">
        <div class="container">
          <div class="row">
            <div class="col-4">
              <div class="row">
                <div class="col-8">
                  <button
                    type="button"
                    class="btn btn-light btn-block"
                    (click)="activeModal.close(false)"
                  >
                    {{ 'PAGES.COMMON.MODAL.CANCEL' | translate }}
                  </button>
                </div>
                <div class="col-4"></div>
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-1"></div>
                <div class="col-5">
                  <button
                    type="submit"
                    class="btn btn-success btn-block"
                    (click)="activeModal.close(saveType.SAVE)"
                  >
                    <i class="bx bxs-save mr-2"></i
                    >{{ 'PAGES.COMMON.MODAL.SAVE' | translate }}
                  </button>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-primary btn-block"
                    (click)="activeModal.close(saveType.SAVE_AND_LEAVE)"
                  >
                    {{ 'PAGES.COMMON.MODAL.SAVE_AND_LEAVE' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #confirmButtons>
        <button
          type="button"
          class="btn btn-light"
          (click)="activeModal.close(false)"
        >
          {{ 'PAGES.COMMON.MODAL.CANCEL' | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="activeModal.close(saveType.SAVE_AND_LEAVE)"
        >
          {{ 'PAGES.COMMON.MODAL.CONFIRM' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</div>
