<div class="toasts-container" *ngIf="service.toasts | async">
  <div
    *ngFor="let toast of service.toasts | async"
    [ngClass]="'alert-' + toast.type"
    class="alert show fade alert-dismissible"
  >
    {{ toast.text | translate }}
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="service.remove(toast)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
