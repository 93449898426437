import {Dictionary} from '../server/response.model';

export interface NodeType {
  id: number;
  name: NodeTypeName;
}

export enum NodeTypeName {
  COMPANY,
  INSTRUCTION_BASE,
  VERIFICATION_DEPARTMENT,
  DEPARTMENT,
  AREA,
  WORKSTATION,
  MAIN_INSTRUCTION,
  INSTRUCTION,
  STEP,
  KEY_POINT,
  REASON,
  PICTURE,
  TAG,
  COMMENT,
}

export interface NodeTypeDictionary extends Dictionary<NodeTypeName> {}

export const NODE_TYPES_DICTIONARY: NodeTypeDictionary[] = [
  {
    backendName: 'Company',
    name: NodeTypeName.COMPANY,
  },
  {
    backendName: 'InstructionBase',
    name: NodeTypeName.INSTRUCTION_BASE,
  },
  {
    backendName: 'VerificationDepartment',
    name: NodeTypeName.VERIFICATION_DEPARTMENT,
  },
  {
    backendName: 'Department',
    name: NodeTypeName.DEPARTMENT,
  },
  {
    backendName: 'Area',
    name: NodeTypeName.AREA,
  },
  {
    backendName: 'WorkStation',
    name: NodeTypeName.WORKSTATION,
  },
  {
    backendName: 'Main instruction',
    name: NodeTypeName.MAIN_INSTRUCTION,
  },
  {
    backendName: 'Instruction',
    name: NodeTypeName.INSTRUCTION,
  },
  {
    backendName: 'Step',
    name: NodeTypeName.STEP,
  },
  {
    backendName: 'KeyPoint',
    name: NodeTypeName.KEY_POINT,
  },
  {
    backendName: 'Reason',
    name: NodeTypeName.REASON,
  },
  {
    backendName: 'Picture',
    name: NodeTypeName.PICTURE,
  },
  {
    backendName: 'Tag',
    name: NodeTypeName.TAG,
  },
  {
    backendName: 'Comment',
    name: NodeTypeName.COMMENT,
  },
];
