import {Component, OnInit, Input} from '@angular/core';
import {Breadcrumb} from '../../../data/model/breadcrumb.model';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent implements OnInit {
  @Input() public breadcrumbItems: Breadcrumb[];
  @Input() public title: string;

  constructor() {}

  ngOnInit() {}
}
