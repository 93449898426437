import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class LanguageService {
  public languages: string[] = ['pl', 'en'];

  constructor(public translate: TranslateService) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (localStorage.getItem('lang')) {
      browserLang = localStorage.getItem('lang');
    } else {
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/pl|en/) ? browserLang : 'pl');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }

  public instant(key: string): string {
    return this.translate.instant(key);
  }
}
