import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Client, ClientEmailConfiguration, ClientPasswordConfiguration, ClientSmtpConfiguration} from '../model/client.model';
import {isUpdate, url, zeroIfNull} from '../server/rest.util';
import {
  ADD_CLIENT,
  GET_CLIENT_SMTP,
  GET_CLIENTS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SMTP,
  UPDATE_PASSWORD_SMTP,
  GET_CLIENT_EMAIL,
  UPDATE_CLIENT_EMAIL,
} from '../server/rest-endpoint.constant';
import {publishReplay, refCount} from 'rxjs/internal/operators';

export interface BackendClient {
  id: number;
  nip: string;
  companyName: string;
  databaseName: string;

  postalAddress: string;
  postalZip: string;
  postalCity: string;
  postalCountry: string;

  invoiceAddress: string;
  invoiceZip: string;
  invoiceCity: string;
  invoiceCountry: string;

  taxEU: boolean;
  supportUrl: string;
  showSupportUrl: boolean;

  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  email: string;

  logo?: string;
}

@Injectable({providedIn: 'root'})
export class ClientRestService {
  constructor(private http: HttpClient) {}

  private activeClient: Observable<Client>;

  public save(client: Client): Observable<any> {
    const clientUrl = isUpdate(client) ? UPDATE_CLIENT : ADD_CLIENT;

    return this.http.post<any>(url(clientUrl), this.mapClientToBackendClient(client)).pipe(
      switchMap(result => {
        this.clearCache();
        return this.findActiveClient();
      }),
    );
  }

  public findActiveClient(): Observable<Client> {
    if (!this.activeClient) {
      this.activeClient = this.http.get<BackendClient[]>(url(GET_CLIENTS)).pipe(
        map(response => {
          if (response.length > 0) {
            return this.mapBackendClientToClient(response[0]);
          } else {
            return null;
          }
        }),
        publishReplay(1),
        refCount(),
      );
    }

    return this.activeClient;
  }

  public getSmtpClientData = (clientId: number): Observable<ClientSmtpConfiguration> => {
    return this.http
      .get<ClientSmtpConfiguration>(url(GET_CLIENT_SMTP), {params: new HttpParams().set('clientId', clientId + '')})
      .pipe(map(response => response));
  };

  public updateSmtpClientData = (smtpConfiguration: ClientSmtpConfiguration): Observable<any> => {
    return this.http.post<any>(url(UPDATE_CLIENT_SMTP), smtpConfiguration).pipe(map(response => response));
  };

  public getEmailClientData = (clientId: number): Observable<ClientEmailConfiguration> => {
    return this.http
      .get<ClientEmailConfiguration>(url(GET_CLIENT_EMAIL), {params: new HttpParams().set('clientId', clientId + '')})
      .pipe(map(response => response));
  };

  public updateEmailClientData = (emailConfiguration: ClientEmailConfiguration): Observable<any> => {
    return this.http.post<any>(url(UPDATE_CLIENT_EMAIL), emailConfiguration).pipe(map(response => response));
  };

  public updateSmtpPasswordData = (passwordConfiguration: ClientPasswordConfiguration): Observable<any> => {
    return this.http.post<any>(url(UPDATE_PASSWORD_SMTP), passwordConfiguration).pipe(map(response => response));
  };

  private clearCache(): void {
    this.activeClient = null;
  }

  private mapClientToBackendClient(client: Client): BackendClient {
    return {
      id: zeroIfNull(client.id),
      nip: client.nip,
      companyName: client.companyName,
      databaseName: client.websiteName,

      postalAddress: client.correspondenceAddressDifferentThanMain ? client.correspondenceAddress.address : client.mainAddress.address,
      postalZip: client.correspondenceAddressDifferentThanMain ? client.correspondenceAddress.zipCode : client.mainAddress.zipCode,
      postalCity: client.correspondenceAddressDifferentThanMain ? client.correspondenceAddress.town : client.mainAddress.town,
      postalCountry: client.correspondenceAddressDifferentThanMain ? client.correspondenceAddress.country : client.mainAddress.country,

      invoiceAddress: client.mainAddress.address,
      invoiceZip: client.mainAddress.zipCode,
      invoiceCity: client.mainAddress.town,
      invoiceCountry: client.mainAddress.country,

      taxEU: client.vat,
      supportUrl: client.supportUrl,
      showSupportUrl: client.showSupportUrl,

      contactFirstName: client.contact.firstname,
      contactLastName: client.contact.surname,
      contactPhone: client.contact.phone,
      email: client.contact.email,

      logo: client.logo,
    };
  }

  private mapBackendClientToClient(client: BackendClient): Client {
    return {
      id: client.id,
      websiteName: client.databaseName,
      companyName: client.companyName,
      nip: client.nip,
      contact: {
        firstname: client.contactFirstName,
        surname: client.contactLastName,
        phone: client.contactPhone,
        email: client.email,
      },
      mainAddress: {
        address: client.invoiceAddress,
        zipCode: client.invoiceZip,
        town: client.invoiceCity,
        country: client.invoiceCountry,
      },
      correspondenceAddress: {
        address: client.postalAddress,
        zipCode: client.postalZip,
        town: client.postalCity,
        country: client.postalCountry,
      },
      correspondenceAddressDifferentThanMain:
        client.invoiceAddress !== client.postalAddress &&
        client.invoiceZip !== client.postalZip &&
        client.invoiceCity !== client.postalCity &&
        client.invoiceCountry !== client.postalCountry,
      vat: !!client.taxEU,
      supportUrl: client.supportUrl,
      showSupportUrl: !!client.showSupportUrl,
      logo: client.logo,
    };
  }
}
