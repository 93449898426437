<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img [src]="currentCompany?.logo" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img [src]="currentCompany?.logo" alt="" height="50" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex flex-nowrap nav-components">
      <div class="dropdown col d-lg-none ml-2" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="dropdown"
          aria-haspopup="true"
          ngbDropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown"
        >
          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown col" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle>
          <img
            *ngIf="flagvalue !== undefined"
            src="{{ flagvalue }}"
            alt="Header Language"
            height="16"
          />
          <span class="ml-1">{{ countryName }}</span>
          <img
            *ngIf="flagvalue === undefined"
            src="{{ valueset }}"
            alt="Header Language"
            height="16"
          />
          <span *ngIf="flagvalue === undefined" class="ml-1">Polski</span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a
            class="dropdown-item notify-item langItem"
            *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{active: cookieValue === item.lang}"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="mr-1"
              height="12"
            />
            <span class="align-middle">{{ item.text }}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown col" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle>
          {{
            plantName ? plantName : ('HEADER.LOGIN.SELECT_PLANT' | translate)
          }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-right dropdown-scroll"
          ngbDropdownMenu
        >
          <a
            class="dropdown-item notify-item langItem"
            *ngFor="let option of plants"
            (click)="setPlant(option)"
          >
            {{ option.name }}
          </a>
        </div>
      </div>

      <div class="dropdown d-none col ml-1">
        <button
          type="button"
          class="btn header-item noti-icon"
          data-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown col" ngbDropdown>
        <button
          type="button"
          class="btn header-item px-1"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            [src]="activeUser.image"
            alt=""
            *ngIf="activeUser?.image"
          />
          <div
            class="d-none d-xl-inline-block avatar-xs"
            *ngIf="!activeUser?.image"
          >
            <span class="avatar-title rounded-circle">{{
              createInitials()
            }}</span>
          </div>

          <span class="d-none d-xl-inline-block ml-1" *ngIf="activeUser"
            >{{ activeUser.firstname }} {{ activeUser.surname }}</span
          >
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <!-- item-->
          <a
            class="dropdown-item"
            [routerLink]="'/users/' + activeUser?.id + '/edit'"
            ><i class="bx bx-user font-size-16 align-middle mr-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate }}</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger logoutItem" (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate }}</a
          >
        </div>
      </div>
    </div>
  </div>
</header>
