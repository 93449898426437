import {Component, OnInit, DestroyRef, ChangeDetectorRef} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router, NavigationEnd} from '@angular/router';
import {HideLayoutService} from '../../shared/ui/hide-layout.service';
import {InitializeCacheService} from '../../shared/data/service/initialize-cache.service';
import * as UsersActions from '../../pages/users/state/users.actions';
import * as SystemActions from '../../core/system/state/system.actions';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss'],
})

/**
 * Vertical component
 */
export class VerticalComponent implements OnInit {
  isCondensed = false;
  hideLayout = false;

  constructor(
    private router: Router,
    private hideLayoutService: HideLayoutService,
    private initializeCacheService: InitializeCacheService,
    private store: Store,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) {
    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
        this.cdr.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(SystemActions.init());
    this.store.dispatch(UsersActions.init());

    this.initializeCacheService.initializeCache();

    document.body.removeAttribute('data-layout');
    document.body.removeAttribute('data-topbar');
    document.body.removeAttribute('data-layout-size');
    document.body.classList.remove('sidebar-enable');
    document.body.classList.remove('vertical-collpsed');
    document.body.removeAttribute('data-sidebar-size');

    this.hideLayoutService.hideLayout.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hideLayout => {
      this.hideLayout = hideLayout;
      this.cdr.detectChanges();
    });
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu(): void {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');

    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }
}
