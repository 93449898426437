import {Department} from './instruction-list.model';
import {VerificationDepartment} from './verification.model';
import {Dictionary} from '../server/response.model';

export interface BaseUser {
  id?: number;
  firstname: string;
  surname: string;
}

export interface User extends BaseUser {
  accountType: AccountType;
  position: string;
  department?: Department;
  verificationDepartments?: VerificationDepartment[];
  allowedDepartmentIds: number[];
  isRemoved?: boolean;
  allowAllDepartments?: boolean;
  phone?: string;
  email: string;
  image?: string;
  password?: string;
  oldPassword?: string;
  isPasswordRequired?: boolean;
  plantId: number;
}

export interface AccountType {
  id?: number;
  name: AccountTypeName | string;
  label: string;
  predefined: boolean;
  roles: Role[];
}

export interface Role {
  id: number;
  name: RoleName;
  label: string;
}

export interface RoleGroup {
  label: string;
  group: RoleGroupName;
  roles: RoleName[];
}

export enum AccountTypeName {
  ADMIN,
  AUTHOR,
  TRAINER,
  VERIFIER,
  VIEWER,
}

export enum RoleGroupName {
  INSTRUCTION_CREATION,
  INSTRUCTION_VERIFICATION,
  INSTRUCTION_COMMENTING,
  PREDEFINED_STEPS,
  ADMIN,
}

export enum RoleName {
  VIEW_INSTRUCTION,
  INITIALIZE_INSTRUCTION,
  FILL_INSTRUCTION,
  CREATE_NEW_INSTRUCTION_VERSION,
  CLONE_INSTRUCTION,
  SEND_INSTRUCTION_TO_TRAINER,
  SEND_INSTRUCTION_TO_VERIFICATION,
  DELETE_UNRELEASED_INSTRUCTION,
  DELETE_RELEASED_INSTRUCTION,
  SEND_INSTRUCTION_TO_CORRECT,

  ACCEPT_INSTRUCTION_VERIFICATION,
  REJECT_INSTRUCTION_VERIFICATION,

  ADD_COMMENT_TO_INSTRUCTION,
  ACCEPT_INSTRUCTION_COMMENT,
  DELETE_INSTRUCTION_COMMENT,

  ADD_PREDEFINED_STEP,
  DELETE_PREDEFINED_STEP,

  MANAGE_USERS,
  MANAGE_INSTRUCTION_CONFIG,
  MANAGE_VERIFICATION_CONFIG,
  MANAGE_COMPANY_CONFIG,
  MANAGE_COMPANY_PROFILE,
  VIEW_RELEASED_INSTRUCTION,
  MANAGE_MAIL_TEMPLATE,
  MANAGE_SYMBOL,
}

export interface AccountTypeDictionary extends Dictionary<AccountTypeName> {}

export interface RoleDictionary extends Dictionary<RoleName> {}

export const ROLES_DICTIONARY: RoleDictionary[] = [
  {
    name: RoleName.VIEW_INSTRUCTION,
    backendName: 'ViewInstruction',
    label: 'PAGES.COMMON.ROLE.VIEW_INSTRUCTION',
  },
  {
    name: RoleName.INITIALIZE_INSTRUCTION,
    backendName: 'InitializeInstruction',
    label: 'PAGES.COMMON.ROLE.INITIALIZE_INSTRUCTION',
  },
  {
    name: RoleName.FILL_INSTRUCTION,
    backendName: 'FillInstruction',
    label: 'PAGES.COMMON.ROLE.FILL_INSTRUCTION',
  },
  {
    name: RoleName.CREATE_NEW_INSTRUCTION_VERSION,
    backendName: 'CreateNewInstructionVersion',
    label: 'PAGES.COMMON.ROLE.CREATE_NEW_INSTRUCTION_VERSION',
  },
  {
    name: RoleName.CLONE_INSTRUCTION,
    backendName: 'CloneInstruction',
    label: 'PAGES.COMMON.ROLE.CLONE_INSTRUCTION',
  },
  {
    name: RoleName.SEND_INSTRUCTION_TO_TRAINER,
    backendName: 'SendInstructionToTrainer',
    label: 'PAGES.COMMON.ROLE.SEND_INSTRUCTION_TO_TRAINER',
  },
  {
    name: RoleName.SEND_INSTRUCTION_TO_VERIFICATION,
    backendName: 'SendInstructionToVerification',
    label: 'PAGES.COMMON.ROLE.SEND_INSTRUCTION_TO_VERIFICATION',
  },
  {
    name: RoleName.DELETE_UNRELEASED_INSTRUCTION,
    backendName: 'DeleteUnreleasedInstruction',
    label: 'PAGES.COMMON.ROLE.DELETE_UNRELEASED_INSTRUCTION',
  },
  {
    name: RoleName.DELETE_RELEASED_INSTRUCTION,
    backendName: 'DeleteReleasedInstruction',
    label: 'PAGES.COMMON.ROLE.DELETE_RELEASED_INSTRUCTION',
  },
  {
    name: RoleName.SEND_INSTRUCTION_TO_CORRECT,
    backendName: 'SendInstructionToCorrect',
    label: 'PAGES.COMMON.ROLE.SEND_INSTRUCTION_TO_CORRECT',
  },
  {
    name: RoleName.ACCEPT_INSTRUCTION_VERIFICATION,
    backendName: 'AcceptInstructionVerification',
    label: 'PAGES.COMMON.ROLE.ACCEPT_INSTRUCTION_VERIFICATION',
  },
  {
    name: RoleName.REJECT_INSTRUCTION_VERIFICATION,
    backendName: 'RejectInstructionVerification',
    label: 'PAGES.COMMON.ROLE.REJECT_INSTRUCTION_VERIFICATION',
  },
  {
    name: RoleName.ADD_COMMENT_TO_INSTRUCTION,
    backendName: 'AddCommentToInstruction',
    label: 'PAGES.COMMON.ROLE.ADD_COMMENT_TO_INSTRUCTION',
  },
  {
    name: RoleName.ACCEPT_INSTRUCTION_COMMENT,
    backendName: 'AcceptInstructionComment',
    label: 'PAGES.COMMON.ROLE.ACCEPT_INSTRUCTION_COMMENT',
  },
  {
    name: RoleName.DELETE_INSTRUCTION_COMMENT,
    backendName: 'DeleteInstructionComment',
    label: 'PAGES.COMMON.ROLE.DELETE_INSTRUCTION_COMMENT',
  },
  {
    name: RoleName.ADD_PREDEFINED_STEP,
    backendName: 'AddPredefinedStep',
    label: 'PAGES.COMMON.ROLE.ADD_PREDEFINED_STEP',
  },
  {
    name: RoleName.DELETE_PREDEFINED_STEP,
    backendName: 'DeletePredefinedStep',
    label: 'PAGES.COMMON.ROLE.DELETE_PREDEFINED_STEP',
  },
  {
    name: RoleName.MANAGE_USERS,
    backendName: 'ManageUsers',
    label: 'PAGES.COMMON.ROLE.MANAGE_USERS',
  },
  {
    name: RoleName.MANAGE_INSTRUCTION_CONFIG,
    backendName: 'ManageInstructionConfig',
    label: 'PAGES.COMMON.ROLE.MANAGE_INSTRUCTION_CONFIG',
  },
  {
    name: RoleName.MANAGE_VERIFICATION_CONFIG,
    backendName: 'ManageVerificationConfig',
    label: 'PAGES.COMMON.ROLE.MANAGE_VERIFICATION_CONFIG',
  },
  {
    name: RoleName.MANAGE_COMPANY_CONFIG,
    backendName: 'ManageCompanyConfig',
    label: 'PAGES.COMMON.ROLE.MANAGE_COMPANY_CONFIG',
  },
  {
    name: RoleName.MANAGE_COMPANY_PROFILE,
    backendName: 'ManageCompanyProfil',
    label: 'PAGES.COMMON.ROLE.MANAGE_COMPANY_PROFILE',
  },
  {
    name: RoleName.VIEW_RELEASED_INSTRUCTION,
    backendName: 'ViewReleasedInstruction',
    label: 'PAGES.COMMON.ROLE.VIEW_RELEASED_INSTRUCTION',
  },
  {
    name: RoleName.MANAGE_MAIL_TEMPLATE,
    backendName: 'ManageMailTemplate',
    label: 'PAGES.COMMON.ROLE.MANAGE_MAIL_TEMPLATE',
  },
  {
    name: RoleName.MANAGE_SYMBOL,
    backendName: 'ManageSymbol',
    label: 'PAGES.COMMON.ROLE.MANAGE_SYMBOL',
  },
];

export const ACCOUNT_TYPES_DICTIONARY: AccountTypeDictionary[] = [
  {
    name: AccountTypeName.ADMIN,
    backendName: 'Administrator',
    label: 'PAGES.COMMON.ACCOUNT_TYPE.ADMIN',
  },
  {
    name: AccountTypeName.AUTHOR,
    backendName: 'Author',
    label: 'PAGES.COMMON.ACCOUNT_TYPE.AUTHOR',
  },
  {
    name: AccountTypeName.TRAINER,
    backendName: 'ProcessOwner',
    label: 'PAGES.COMMON.ACCOUNT_TYPE.TRAINER',
  },
  {
    name: AccountTypeName.VERIFIER,
    backendName: 'Verifier',
    label: 'PAGES.COMMON.ACCOUNT_TYPE.VERIFIER',
  },
  {
    name: AccountTypeName.VIEWER,
    backendName: 'Viewer',
    label: 'PAGES.COMMON.ACCOUNT_TYPE.VIEWER',
  },
];

export const ROLE_GROUPS_CONFIG: RoleGroup[] = [
  {
    label: 'PAGES.COMMON.ROLE_GROUP.INSTRUCTION_CREATION',
    group: RoleGroupName.INSTRUCTION_CREATION,
    roles: [
      RoleName.VIEW_INSTRUCTION,
      RoleName.INITIALIZE_INSTRUCTION,
      RoleName.FILL_INSTRUCTION,
      RoleName.CREATE_NEW_INSTRUCTION_VERSION,
      RoleName.CLONE_INSTRUCTION,
      RoleName.SEND_INSTRUCTION_TO_TRAINER,
      RoleName.SEND_INSTRUCTION_TO_VERIFICATION,
      RoleName.DELETE_UNRELEASED_INSTRUCTION,
      RoleName.DELETE_RELEASED_INSTRUCTION,
      RoleName.SEND_INSTRUCTION_TO_CORRECT,
      RoleName.VIEW_RELEASED_INSTRUCTION,
    ],
  },
  {
    label: 'PAGES.COMMON.ROLE_GROUP.INSTRUCTION_VERIFICATION',
    group: RoleGroupName.INSTRUCTION_VERIFICATION,
    roles: [RoleName.ACCEPT_INSTRUCTION_VERIFICATION, RoleName.REJECT_INSTRUCTION_VERIFICATION],
  },
  {
    label: 'PAGES.COMMON.ROLE_GROUP.INSTRUCTION_COMMENTING',
    group: RoleGroupName.INSTRUCTION_COMMENTING,
    roles: [RoleName.ADD_COMMENT_TO_INSTRUCTION, RoleName.ACCEPT_INSTRUCTION_COMMENT, RoleName.DELETE_INSTRUCTION_COMMENT],
  },
  {
    label: 'PAGES.COMMON.ROLE_GROUP.PREDEFINED_STEPS',
    group: RoleGroupName.PREDEFINED_STEPS,
    roles: [RoleName.ADD_PREDEFINED_STEP, RoleName.DELETE_PREDEFINED_STEP],
  },
  {
    label: 'PAGES.COMMON.ROLE_GROUP.ADMIN',
    group: RoleGroupName.ADMIN,
    roles: [
      RoleName.MANAGE_USERS,
      RoleName.MANAGE_INSTRUCTION_CONFIG,
      RoleName.MANAGE_VERIFICATION_CONFIG,
      RoleName.MANAGE_COMPANY_CONFIG,
      RoleName.MANAGE_COMPANY_PROFILE,
      RoleName.MANAGE_MAIL_TEMPLATE,
      RoleName.MANAGE_SYMBOL,
    ],
  },
];
