import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public loaders = new BehaviorSubject([]);

  constructor() {}

  show(): void {
    const newValue = this.loaders.getValue();
    newValue.push(true);
    this.loaders.next(newValue);
  }

  hide(): void {
    setTimeout(() => {
      const newValue = this.loaders.getValue();
      newValue.pop();
      this.loaders.next(newValue);
    }, 50);
  }

  hideForce(): void {
    setTimeout(() => {
      this.loaders.next([]);
    }, 50);
  }
}
