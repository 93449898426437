import {SystemState} from './system.state';
import {createReducer, on} from '@ngrx/store';
import * as SystemActions from './system.actions';

const initialState: SystemState = {
  wienTemplateCfg: false,
  templateConfiguration: false,
  maxNumberOfPlants: 0,
  version: '',
  isLoading: false,
};

export const SYSTEM = 'system';

export const systemReducer = createReducer(
  initialState,
  on(SystemActions.init, state => ({
    ...state,
  })),
  on(SystemActions.fetchSystemInfo, state => ({
    ...state,
    isLoading: true,
  })),
  on(SystemActions.setSystemInfo, (state, {systemInfo}) => ({
    ...state,
    maxNumberOfPlants: systemInfo.maxNumberOfPlants,
    version: systemInfo.version,
    wienTemplateCfg: systemInfo.wienTemplateCfg,
    templateConfiguration: systemInfo.templateConfiguration,
    isLoading: false,
  })),
);
