import {Injectable} from '@angular/core';
import {forkJoin} from 'rxjs';
import {CompanyRestService} from './company-rest.service';
import {InstructionStatusRestService} from './instruction-status-rest.service';
import {InstructionReasonSymbolRestService} from './instruction-reason-symbol-rest.service';
import {InstructionTimeUnitRestService} from './instruction-time-unit-rest.service';
import {InstructionVerificationStatusRestService} from './instruction-verification-status-rest.service';
import {LanguageRestService} from './language-rest.service';
import {NodeTypeRestService} from './node-type-rest.service';
import {AccountTypeRestService} from './account-type-rest.service';
import {ClientRestService} from './client-rest.service';
import {SystemParameterTypeRestService} from './system-parameter-type-rest.service';

@Injectable({providedIn: 'root'})
export class InitializeCacheService {
  constructor(
    private accountTypeRestService: AccountTypeRestService,
    private instructionReasonSymbolRestService: InstructionReasonSymbolRestService,
    private instructionStatusRestService: InstructionStatusRestService,
    private instructionTimeUnitRestService: InstructionTimeUnitRestService,
    private instructionVerificationStatusRestService: InstructionVerificationStatusRestService,
    private languageRestService: LanguageRestService,
    private clientRestService: ClientRestService,
    private systemParameterTypeRestService: SystemParameterTypeRestService,
    private nodeTypeRestService: NodeTypeRestService,
  ) {}

  initializeCache(): void {
    forkJoin({
      accountTypes: this.accountTypeRestService.findAllAccountTypes(),
      roles: this.accountTypeRestService.findAllRoles(),
      reasonSymbols: this.instructionReasonSymbolRestService.findAll(),
      statuses: this.instructionStatusRestService.findAll(),
      timeUnits: this.instructionTimeUnitRestService.findAll(),
      verificationStatuses: this.instructionVerificationStatusRestService.findAll(),
      language: this.languageRestService.findAll(),
      nodeTypes: this.nodeTypeRestService.findAll(),
      activeClient: this.clientRestService.findActiveClient(),
      systemParameterTypes: this.systemParameterTypeRestService.findAll(),
    }).subscribe();
  }
}
