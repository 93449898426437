import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {Response} from '../server/response.model';
import {url} from '../server/rest.util';
import {
  EMPTY,
  OTHER_DICTIONARY,
  PERSONAL_PROTECTION_DICTIONARY,
  PROHIBITED_DICTIONARY,
  ReasonSymbol,
  ReasonSymbolDictionary,
  STANDARD_DICTIONARY,
  WORKPLACE_HAZARDS_DICTIONARY,
} from '../model/instruction-reason-symbol.model';
import {GET_INSTRUCTION_REASON_SYMBOLS} from '../server/rest-endpoint.constant';

export interface BackendReasonSymbol {
  id: number;
  name: string;
}

@Injectable({providedIn: 'root'})
export class InstructionReasonSymbolRestService {
  private standardSymbols: Observable<ReasonSymbol[]>;
  private personalProtectionSymbols: Observable<ReasonSymbol[]>;
  private prohibitedSymbols: Observable<ReasonSymbol[]>;
  private workplaceHazardsSymbols: Observable<ReasonSymbol[]>;
  private otherSymbols: Observable<ReasonSymbol[]>;

  constructor(private http: HttpClient) {}

  findAll(): Observable<ReasonSymbol[]> {
    return forkJoin({
      standardSymbols: this.findStandardSymbols(),
      personalProtectionSymbols: this.findPersonalProtectionSymbols(),
      prohibitedSymbols: this.findProhibitedSymbols(),
      workplaceHazardsSymbols: this.findWorkplaceHazardsSymbols(),
      otherSymbols: this.findOtherSymbols(),
    }).pipe(
      map(data => {
        return data.standardSymbols
          .concat(data.personalProtectionSymbols)
          .concat(data.prohibitedSymbols)
          .concat(data.workplaceHazardsSymbols)
          .concat(data.otherSymbols);
      }),
    );
  }

  findStandardSymbols(): Observable<ReasonSymbol[]> {
    if (!this.standardSymbols) {
      this.standardSymbols = this.findSymbols(STANDARD_DICTIONARY, [EMPTY]);
    }

    return this.standardSymbols;
  }

  findPersonalProtectionSymbols(): Observable<ReasonSymbol[]> {
    if (!this.personalProtectionSymbols) {
      this.personalProtectionSymbols = this.findSymbols(PERSONAL_PROTECTION_DICTIONARY);
    }

    return this.personalProtectionSymbols;
  }

  findProhibitedSymbols(): Observable<ReasonSymbol[]> {
    if (!this.prohibitedSymbols) {
      this.prohibitedSymbols = this.findSymbols(PROHIBITED_DICTIONARY);
    }

    return this.prohibitedSymbols;
  }

  findWorkplaceHazardsSymbols(): Observable<ReasonSymbol[]> {
    if (!this.workplaceHazardsSymbols) {
      this.workplaceHazardsSymbols = this.findSymbols(WORKPLACE_HAZARDS_DICTIONARY);
    }

    return this.workplaceHazardsSymbols;
  }

  findOtherSymbols(): Observable<ReasonSymbol[]> {
    if (!this.otherSymbols) {
      this.otherSymbols = this.findSymbols(OTHER_DICTIONARY);
    }

    return this.otherSymbols;
  }

  private findSymbols(
    reasonSymbolDictionaries: ReasonSymbolDictionary[],
    mandatorySymbols: ReasonSymbol[] = [],
  ): Observable<ReasonSymbol[]> {
    return this.http.get<Response<BackendReasonSymbol[]>>(url(GET_INSTRUCTION_REASON_SYMBOLS)).pipe(
      map(response => this.mapStatuses(response.result, reasonSymbolDictionaries, mandatorySymbols)),
      publishReplay(1),
      refCount(),
    );
  }

  private mapStatuses(
    backendSymbols: BackendReasonSymbol[],
    reasonSymbolDictionaries: ReasonSymbolDictionary[],
    mandatorySymbols: ReasonSymbol[] = [],
  ): ReasonSymbol[] {
    const res: ReasonSymbol[] = backendSymbols
      .map(backendSymbol => {
        const foundSymbols = reasonSymbolDictionaries.filter(dic => dic.backendName === backendSymbol.name);
        if (foundSymbols?.length > 0) {
          return {
            id: backendSymbol.id,
            label: foundSymbols[0].label,
            image: foundSymbols[0].image,
            type: foundSymbols[0].type,
          };
        } else {
          return null;
        }
      })
      .filter(val => val);
    return backendSymbols ? mandatorySymbols.concat(res) : mandatorySymbols;
  }
}
