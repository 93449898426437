import {createAction, props} from '@ngrx/store';
import {User} from '../../../shared/data/model/user.model';
import {UsersListSort} from '../model/users-list-sort';
import {SortOrder} from '../model/sort-order';

export const init = createAction('[Users] Init');

export const fetchUsersList = createAction('[Users] Fetch users list');

export const setUsersList = createAction('[Users] Set users list', props<{users: User[]}>());

export const fetchCurrentUserData = createAction('[Users] Fetch current user data');

export const setCurrentUserData = createAction('[Users] Set current user data', props<{currentUser: User}>());

export const changeCurrentPage = createAction('[Users] Change page', props<{currentPage: number}>());

export const searchUsersList = createAction('[Users] Search instructions', props<{searchPhrase: string}>());

export const sortUsersList = createAction('[Users] Sort list', props<{sortBy: UsersListSort; sortOrder: SortOrder}>());

export const deleteUserFormList = createAction('[Users] Delete user', props<{userId: number}>());

export const setDepartmentFilter = createAction('[Users] Set department filter', props<{departmentId: number}>());

export const clearState = createAction('[Users] Clear state');
