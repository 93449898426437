export enum ReasonSymbolType {
  BHP,
  CORRECTNESS,
  QUALITY,
  TIPS,
}

export interface ReasonSymbol {
  id: number;
  label: string;
  image?: string;
  disabled?: boolean;
  type?: ReasonSymbolType;
}

export interface ReasonSymbolDictionary {
  label: string;
  backendName: string;
  image: string;
  type?: ReasonSymbolType;
}

export const EMPTY: ReasonSymbol = {
  id: -1,
  label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.STANDARD.EMPTY',
  disabled: true,
};

export const SELECT_OTHER: ReasonSymbol = {
  id: -2,
  label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.STANDARD.SELECT_OTHER',
};

export const STANDARD_DICTIONARY: ReasonSymbolDictionary[] = [
  {
    backendName: 'BHP',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.STANDARD.BHP',
    image: '/assets/images/instructions/symbols/standard/bhp.png',
    type: ReasonSymbolType.BHP,
  },
  {
    backendName: 'QUALITY',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.STANDARD.QUALITY',
    image: '/assets/images/instructions/symbols/standard/quality.png',
    type: ReasonSymbolType.QUALITY,
  },
  {
    backendName: 'CORRECTNESS',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.STANDARD.CORRECTNESS',
    image: '/assets/images/instructions/symbols/standard/correctness.png',
    type: ReasonSymbolType.CORRECTNESS,
  },
  {
    backendName: 'TIPS',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.STANDARD.TIPS',
    image: '/assets/images/instructions/symbols/standard/tips.png',
    type: ReasonSymbolType.TIPS,
  },
];

export const PERSONAL_PROTECTION_DICTIONARY: ReasonSymbolDictionary[] = [
  {
    backendName: 'PERSONAL_PROTECTION_1',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.1',
    image: '/assets/images/instructions/symbols/personal-protection/image1.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_2',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.2',
    image: '/assets/images/instructions/symbols/personal-protection/image2.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_3',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.3',
    image: '/assets/images/instructions/symbols/personal-protection/image3.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_4',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.4',
    image: '/assets/images/instructions/symbols/personal-protection/image4.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_5',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.5',
    image: '/assets/images/instructions/symbols/personal-protection/image5.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_6',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.6',
    image: '/assets/images/instructions/symbols/personal-protection/image6.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_7',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.7',
    image: '/assets/images/instructions/symbols/personal-protection/image7.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_8',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.8',
    image: '/assets/images/instructions/symbols/personal-protection/image8.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_9',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.9',
    image: '/assets/images/instructions/symbols/personal-protection/image9.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_10',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.10',
    image: '/assets/images/instructions/symbols/personal-protection/image10.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_11',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.11',
    image: '/assets/images/instructions/symbols/personal-protection/image11.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_12',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.12',
    image: '/assets/images/instructions/symbols/personal-protection/image12.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_13',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.13',
    image: '/assets/images/instructions/symbols/personal-protection/image13.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_14',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.14',
    image: '/assets/images/instructions/symbols/personal-protection/image14.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_15',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.15',
    image: '/assets/images/instructions/symbols/personal-protection/image15.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_16',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.16',
    image: '/assets/images/instructions/symbols/personal-protection/image16.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_17',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.17',
    image: '/assets/images/instructions/symbols/personal-protection/image17.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_18',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.18',
    image: '/assets/images/instructions/symbols/personal-protection/image18.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_19',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.19',
    image: '/assets/images/instructions/symbols/personal-protection/image19.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_20',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.20',
    image: '/assets/images/instructions/symbols/personal-protection/image20.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_21',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.21',
    image: '/assets/images/instructions/symbols/personal-protection/image21.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_22',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.22',
    image: '/assets/images/instructions/symbols/personal-protection/image22.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_23',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.23',
    image: '/assets/images/instructions/symbols/personal-protection/image23.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_24',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.24',
    image: '/assets/images/instructions/symbols/personal-protection/image24.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_25',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.25',
    image: '/assets/images/instructions/symbols/personal-protection/image25.png',
  },
  {
    backendName: 'PERSONAL_PROTECTION_26',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PERSONAL_PROTECTION.26',
    image: '/assets/images/instructions/symbols/personal-protection/image26.png',
  },
];
export const PROHIBITED_DICTIONARY: ReasonSymbolDictionary[] = [
  {
    backendName: 'PROHIBITED_1',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.1',
    image: '/assets/images/instructions/symbols/prohibited/image27.png',
  },
  {
    backendName: 'PROHIBITED_2',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.2',
    image: '/assets/images/instructions/symbols/prohibited/image28.png',
  },
  {
    backendName: 'PROHIBITED_3',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.3',
    image: '/assets/images/instructions/symbols/prohibited/image29.png',
  },
  {
    backendName: 'PROHIBITED_4',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.4',
    image: '/assets/images/instructions/symbols/prohibited/image30.png',
  },
  {
    backendName: 'PROHIBITED_5',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.5',
    image: '/assets/images/instructions/symbols/prohibited/image31.png',
  },
  {
    backendName: 'PROHIBITED_6',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.6',
    image: '/assets/images/instructions/symbols/prohibited/image32.png',
  },
  {
    backendName: 'PROHIBITED_7',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.7',
    image: '/assets/images/instructions/symbols/prohibited/image33.png',
  },
  {
    backendName: 'PROHIBITED_8',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.8',
    image: '/assets/images/instructions/symbols/prohibited/image34.png',
  },
  {
    backendName: 'PROHIBITED_9',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.9',
    image: '/assets/images/instructions/symbols/prohibited/image35.png',
  },
  {
    backendName: 'PROHIBITED_10',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.10',
    image: '/assets/images/instructions/symbols/prohibited/image36.png',
  },
  {
    backendName: 'PROHIBITED_11',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.11',
    image: '/assets/images/instructions/symbols/prohibited/image37.png',
  },
  {
    backendName: 'PROHIBITED_12',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.12',
    image: '/assets/images/instructions/symbols/prohibited/image38.png',
  },
  {
    backendName: 'PROHIBITED_13',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.13',
    image: '/assets/images/instructions/symbols/prohibited/image39.png',
  },
  {
    backendName: 'PROHIBITED_14',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.14',
    image: '/assets/images/instructions/symbols/prohibited/image40.png',
  },
  {
    backendName: 'PROHIBITED_15',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.15',
    image: '/assets/images/instructions/symbols/prohibited/image41.png',
  },
  {
    backendName: 'PROHIBITED_16',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.16',
    image: '/assets/images/instructions/symbols/prohibited/image42.png',
  },
  {
    backendName: 'PROHIBITED_17',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.17',
    image: '/assets/images/instructions/symbols/prohibited/image43.png',
  },
  {
    backendName: 'PROHIBITED_18',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.PROHIBITED.18',
    image: '/assets/images/instructions/symbols/prohibited/image44.png',
  },
];
export const WORKPLACE_HAZARDS_DICTIONARY: ReasonSymbolDictionary[] = [
  {
    backendName: 'WORKPLACE_HAZARDS_1',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.1',
    image: '/assets/images/instructions/symbols/workplace-hazards/image45.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_2',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.2',
    image: '/assets/images/instructions/symbols/workplace-hazards/image46.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_3',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.3',
    image: '/assets/images/instructions/symbols/workplace-hazards/image47.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_4',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.4',
    image: '/assets/images/instructions/symbols/workplace-hazards/image48.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_5',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.5',
    image: '/assets/images/instructions/symbols/workplace-hazards/image49.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_6',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.6',
    image: '/assets/images/instructions/symbols/workplace-hazards/image50.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_7',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.7',
    image: '/assets/images/instructions/symbols/workplace-hazards/image51.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_8',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.8',
    image: '/assets/images/instructions/symbols/workplace-hazards/image52.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_9',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.9',
    image: '/assets/images/instructions/symbols/workplace-hazards/image53.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_10',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.10',
    image: '/assets/images/instructions/symbols/workplace-hazards/image54.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_11',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.11',
    image: '/assets/images/instructions/symbols/workplace-hazards/image55.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_12',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.12',
    image: '/assets/images/instructions/symbols/workplace-hazards/image56.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_13',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.13',
    image: '/assets/images/instructions/symbols/workplace-hazards/image57.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_14',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.14',
    image: '/assets/images/instructions/symbols/workplace-hazards/image58.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_15',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.15',
    image: '/assets/images/instructions/symbols/workplace-hazards/image59.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_16',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.16',
    image: '/assets/images/instructions/symbols/workplace-hazards/image60.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_17',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.17',
    image: '/assets/images/instructions/symbols/workplace-hazards/image61.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_18',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.18',
    image: '/assets/images/instructions/symbols/workplace-hazards/image62.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_19',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.19',
    image: '/assets/images/instructions/symbols/workplace-hazards/image63.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_20',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.20',
    image: '/assets/images/instructions/symbols/workplace-hazards/image64.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_21',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.21',
    image: '/assets/images/instructions/symbols/workplace-hazards/image65.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_22',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.22',
    image: '/assets/images/instructions/symbols/workplace-hazards/image66.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_23',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.23',
    image: '/assets/images/instructions/symbols/workplace-hazards/image67.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_24',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.24',
    image: '/assets/images/instructions/symbols/workplace-hazards/image68.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_25',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.25',
    image: '/assets/images/instructions/symbols/workplace-hazards/image69.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_26',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.26',
    image: '/assets/images/instructions/symbols/workplace-hazards/image70.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_27',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.27',
    image: '/assets/images/instructions/symbols/workplace-hazards/image71.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_28',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.28',
    image: '/assets/images/instructions/symbols/workplace-hazards/image72.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_29',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.29',
    image: '/assets/images/instructions/symbols/workplace-hazards/image73.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_30',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.30',
    image: '/assets/images/instructions/symbols/workplace-hazards/image74.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_31',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.31',
    image: '/assets/images/instructions/symbols/workplace-hazards/image75.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_32',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.32',
    image: '/assets/images/instructions/symbols/workplace-hazards/image76.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_33',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.33',
    image: '/assets/images/instructions/symbols/workplace-hazards/image77.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_34',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.34',
    image: '/assets/images/instructions/symbols/workplace-hazards/image78.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_35',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.35',
    image: '/assets/images/instructions/symbols/workplace-hazards/image79.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_36',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.36',
    image: '/assets/images/instructions/symbols/workplace-hazards/image80.png',
  },
  {
    backendName: 'WORKPLACE_HAZARDS_37',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.WORKPLACE_HAZARDS.37',
    image: '/assets/images/instructions/symbols/workplace-hazards/image81.png',
  },
];

export const OTHER_DICTIONARY: ReasonSymbolDictionary[] = [
  {
    backendName: 'OTHER_1',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.OTHER.1',
    image: '/assets/images/instructions/symbols/other/image1.png',
  },
  {
    backendName: 'OTHER_2',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.OTHER.2',
    image: '/assets/images/instructions/symbols/other/image2.png',
  },
  {
    backendName: 'OTHER_3',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.OTHER.3',
    image: '/assets/images/instructions/symbols/other/image3.jpg',
  },
  {
    backendName: 'OTHER_4',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.OTHER.4',
    image: '/assets/images/instructions/symbols/other/image4.jpg',
  },
  {
    backendName: 'OTHER_5',
    label: 'PAGES.NEW_INSTRUCTION.DATA.SYMBOL.OTHER.5',
    image: '/assets/images/instructions/symbols/other/image5.png',
  },
];
