import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {delay, take} from 'rxjs/operators';

export class Toast {
  constructor(public text: string, public type: ToastType = ToastType.SUCCESS) {}
}

export enum ToastType {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

@Injectable({providedIn: 'root'})
export class ToastsService {
  private _toasts = new BehaviorSubject<Toast[]>([]);

  constructor() {}

  get toasts(): BehaviorSubject<Toast[]> {
    return this._toasts;
  }

  addToast(toast: Toast): void {
    const newVal = this.toasts.getValue();
    this.dismissAfterSomeTime(toast);
    newVal.unshift(toast);
    this.toasts.next(newVal);
  }

  remove(toast: Toast): void {
    this.toasts.next(this.toasts.getValue().filter(t => t !== toast));
  }

  private dismissAfterSomeTime(toast: Toast): void {
    this.toasts.pipe(take(1), delay(5000)).subscribe(() => {
      this.remove(toast);
    });
  }
}
