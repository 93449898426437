import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {publishReplay, refCount} from 'rxjs/internal/operators';
import {Response} from '../server/response.model';
import {url} from '../server/rest.util';
import {TIME_UNITS_DICTIONARY, TimeUnit} from '../model/instruction-time-unit.model';
import {GET_INSTRUCTION_TIME_UNITS} from '../server/rest-endpoint.constant';

export interface BackendTimeUnit {
  id: number;
  name: string;
}

@Injectable({providedIn: 'root'})
export class InstructionTimeUnitRestService {
  constructor(private http: HttpClient) {}

  private timeUnits: Observable<TimeUnit[]>;

  private mapTimeUnits(backendTimeUnits: BackendTimeUnit[]): TimeUnit[] {
    return backendTimeUnits
      ? backendTimeUnits
          .map(backendTimeUnit => {
            const foundTimeUnits = TIME_UNITS_DICTIONARY.filter(dic => dic.backendName === backendTimeUnit.name);
            if (foundTimeUnits?.length > 0) {
              return {
                id: backendTimeUnit.id,
                value: foundTimeUnits[0].name,
              };
            } else {
              return null;
            }
          })
          .filter(val => val)
      : [];
  }

  findAll(): Observable<TimeUnit[]> {
    if (!this.timeUnits) {
      this.timeUnits = this.http.get<Response<BackendTimeUnit[]>>(url(GET_INSTRUCTION_TIME_UNITS)).pipe(
        map(response => this.mapTimeUnits(response.result)),
        publishReplay(1),
        refCount(),
      );
    }

    return this.timeUnits;
  }
}
