import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

import {MENU} from './menu';
import {MenuItem} from './menu.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from '../../core/guards/auth.guard';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() isCondensed = false;
  menu: any;

  menuItems = [];
  configData;

  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(public translate: TranslateService, private authGuard: AuthGuard) {
  }

  ngOnInit() {
    this.initialize();

    document.body.setAttribute('data-sidebar', 'dark');

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };
  }
  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU.filter(item => this.authGuard.hasAnyRole(item.roles));
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
