import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SYSTEM} from './system.reducer';
import {SystemState} from './system.state';

export const selectSystem = createFeatureSelector<SystemState>(SYSTEM);

export const selectSystemData = createSelector(selectSystem, (state: SystemState) => state);

export const selectSystemVersion = createSelector(selectSystemData, (state: SystemState) => (state.version ? state.version : ''));

export const selectSystemWienConfig = createSelector(selectSystemData, (state: SystemState) =>
  state.wienTemplateCfg ? state.wienTemplateCfg : false,
);

export const selectSystemNumberOfPlants = createSelector(selectSystemData, (state: SystemState) =>
  state.maxNumberOfPlants ? state.maxNumberOfPlants : 0,
);
