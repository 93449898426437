import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {ClickOutsideModule} from 'ng-click-outside';

import {LoaderComponent} from './loader/loader.component';
import {RouterModule} from '@angular/router';
import {Page404Component} from './page404/page404.component';
import {PageTitleModule} from './pagetitle/page-title.module';
import {SaveModalModule} from './save-modal/save-modal.module';
import {QrGeneratorModule} from './qr-generator/qr-generator.module';

@NgModule({
  declarations: [LoaderComponent, Page404Component],
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    RouterModule,
    PageTitleModule,
    SaveModalModule,
    QrGeneratorModule,
  ],
  exports: [LoaderComponent, Page404Component, QrGeneratorModule],
})
export class UIModule {}
